import userState from './userState';
import chatState from './chatState';
import staticState from './staticState';
import appointmentState from './appointmentState';
import appointmentStateNew from './appointmentStateNew';
import reviewState from './reviewState';
import advertisingState from './advertisingState';

export default {
  requestTimer: null,
  requestInterval: null,
  networkError: false,
  ...userState,
  ...chatState,
  ...appointmentState,
  ...appointmentStateNew,
  ...staticState,
  ...reviewState,
  ...advertisingState,
  socket: null
}
import chatMutations from './chatMutations';
import userMutations from './userMutations';
import appointmentMutations from './appointmentMutations';
import appointmentMutationsNew from './appointmentMutationsNew';
import staticMutations from './staticMutations';
import reviewMutations from './reviewMutations';
import advertisingMutations from "./advertisingMutations";

export default {
  setStopRequestsTimerId(state, data){
    state.requestTimer = data
  },
  toggleErrorSnackbar(state, data){
    state.networkError = data
  },
  ...userMutations,
  ...chatMutations,
  ...appointmentMutations,
  ...appointmentMutationsNew,
  ...staticMutations,
  ...reviewMutations,
  ...advertisingMutations
};
import axios from 'axios'
import socket from '../../services/socket';
import Vuetify from './../../plugins/vuetify'

import appointmentActions from './appointmentActions';
import advertisingActions from "@/store/actions/advertisingActions";

export default {
  // Выход из системы
  logout({commit}){
    return new Promise((resolve) => {
      commit('logout')
      localStorage.removeItem('token');
      localStorage.removeItem('idUser');
      localStorage.removeItem('activeUser');
      delete axios.defaults.headers.common['Authorization']
      socket.socketClose();
      resolve(true)
    })
  },
  selectUser({commit}, idUser) {
    localStorage.setItem('activeUser', idUser)
    commit('selectUser', idUser)
  },
  setRepresentativeCount({commit}, count) { // 💩
    commit('setRepresentativeCount', count);
  },
  setRepresentativesAction({commit}, representatives) {
    commit('setRepresentativesMutation', representatives);
  },
  // Метод авторизации
  login({commit}, authParams){
    socket.socketClose();
    return new Promise((resolve, reject) => {
      //commit('auth_request')
      axios({
        url: window.config.apiUrl + '/auth/auth', 
        data: authParams, 
        method: 'POST' 
      })
      .then(resp => {
        const token = resp.data.token
        const idUser = resp.data.idUser;
        // Запоминаем в локальном хранилище пользователя
        localStorage.setItem('token', token)
        localStorage.setItem('idUser', idUser)
        localStorage.setItem('activeUser', idUser)
        // Если мобильное устройство
        if (window.cordova){
          if (window.device){
            if (window.device.platform === 'iOS' || window.device.platform === 'Android') {
              // Запоминаем логин пароль
              localStorage.setItem('login', authParams.login);
              localStorage.setItem('password', authParams.password)
            }
          }
        }
        commit('selectUser', idUser);
        // Добавляем токен в авторизацию
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        commit('auth_success', token);
        resolve(resp);
      //  socket.socketConnect();
      })
      .catch(err => {
        commit('auth_error')
        localStorage.removeItem('token')
        reject(err)
      })
    })
  },
  // Проверка сессии
  async checkSession({ commit }, token) {
    let resp = await axios({
      url: window.config.apiUrl + '/auth/get-session',
      data: { 
        token, 
        idClient: process.env.VUE_APP_CLIENT 
      },
      method: 'POST'
    })
    commit('setSession', {
      user: resp.data.user, 
      token: token
    })
    if (!socket.socketStatus()){
      console.log('Подключение к сокетам в check session')
      socket.socketReconnect();
    }
  },
  setCallStatus({commit}, callStatus) {
    commit('changeCallStatus', callStatus);
  },
  // Установить услуги чата
  setCouponServices({commit}, couponServices) {
    commit('setCouponServices', couponServices);
  },
  // Установить текущую активную услугу чата
  setCurrentCouponService({commit}, idCouponService) {
    commit('setCurrentCouponService', idCouponService);
  },
  // Установить режим микрофона
  setMutedMode({commit}, mode) {
    commit('setMutedMode', mode)
  },
  // Отправить в чаь уведомление
  sendChatNotify({commit}, {notify, idCouponService}) {
    commit('sendChatNotify', {notify, idCouponService})
  },
  // Установить статус доступности услуги чата
  setChatCouponServiceAvailable({commit}, {idCouponService, status}){
    commit('setChatCouponServiceAvailable', {idCouponService, status});
  },
  setLoginPhone() {

  },
  loadStatic({ commit }) {
    axios({
      url: window.config.apiUrl + '/static/get-account',
      method: 'GET'
    }).then(response => { 
      // Устанавливаем тему оформления
      Vuetify.framework.theme.themes.light.primary = response.data.appThemeColor;
      document.documentElement.style.setProperty("--primary-color", response.data.appThemeColor);
      // Скрываем загрузчик
      //setTimeout(() => {
        window.document.querySelector('.v-app-section').classList.add('loaded')
      //}, 0);
      commit('setStatic', {
        data: response.data, 
      })
    })
  },
  SELECT_SPECIAL({commit}, idSpecial) {
    commit('appointmentSelectSpecial', idSpecial)
  },
  SELECT_USER({commit}, idUser) {
    commit('appointmentSelectUser', idUser)
  },
  SELECT_APPOINTMENT({commit}, appointment) {
    commit('appointmentSelectTime', appointment)
  },
  SELECT_SERVICE_TYPE({commit}, serviceType) {
    commit('appointmentSelectServiceType', serviceType)
  },
  SET_SCHEDULE_SERVICES({commit}, services) {
    commit('setScheduleServices', services)
  },
  templateValues(state){
    return state.templateValues
  },
  changeTemplateItemValues(state, data){
    console.log(data);
    //state.templateValues[data.index].value = data.itemValue
  },
  ...appointmentActions,
  ...advertisingActions,
}
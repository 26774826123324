<template>
    <v-container class="pa-2">
        <div class="header-container d-flex mt-2">
            <HeaderItem icon="fas fa-wallet" :loading="balanceLoading" :title="bonusBalance" caption="Мои бонусы" url="/information?tab=bonuses"/>
            <HeaderItem icon="fas fa-coins"  :loading="depositLoading" :title="deposit" caption="Счет" url="/#" :isDisabled="true" />
        </div>
        <div class="mt-8 pa-0">
            <div class="body-item" style="border-radius: 20px">
                <BodyItem icon="far fa-calendar-check" text="Мои записи к врачу" url="/appointments"/>
            </div>
            <div class="body-item">
                <BodyItem icon="far fa-calendar-plus" text="Записаться на прием" url="/appointment" />
            </div>
            <div class="body-item">
                <BodyItem icon="fas fa-clipboard-list" text="История посещений" url="/history" />
            </div>
            <div class="body-item" v-if="STATIC.moduleKdlStatus">
                <BodyItem icon="fas fa-flask" text="Результаты исследований" url="/analyses" />
            </div>
            <div class="body-item" v-if="STATIC.appUseTelemedicine">
                <BodyItem icon="far fa-comment-dots" text="Телемедицина" url="/chat" />
            </div>
            <div class="body-item">
                <BodyItem icon="fas fa-user-circle" text="Профиль" url="/information" />
            </div>
            <div class="body-item" v-if="STATIC.shopActive">
                <BodyItem icon="fas fa-shopping-basket" text="Мои заказы" url="/orders" />
            </div>
            <div class="body-item" v-if="STATIC.shopActive" @click="$emit('getshop')">
                <BodyItem icon="fas fa-store-alt" text="Магазин"/>
            </div>
            <div class="body-item" v-if="STATIC.appClinicUrl">
                <BodyItem icon="fas fa-globe" text="Медицинский центр" :href="STATIC.appClinicUrl" />
            </div>
            <div class="body-item">
                <BodyItem icon="fas fa-hospital" text="Контакты" url="/contact" />
            </div>
            <div class="body-item">
                <BodyItem icon="fas fa-map-marked-alt" text="Как проехать" url="/map" />
            </div>
        </div>
    </v-container>
</template>
<script>
import HeaderItem from './HeaderItem'
import BodyItem from './BodyItem'
import { mapGetters } from 'vuex'
export default {
    components: {
        HeaderItem,
        BodyItem
    },
    computed: {
        ...mapGetters([
            'STATIC'
        ])
    },
    data: () => ({
        balanceLoading: true,
        depositLoading: true,
        depositBalance: null,
        bonusBalance: null,
        deposit: null,
    }),
    

    mounted() {
        //Получаем количество бонусных рублей клиента
        this.bonusLoading = true;
        this.$http.post(window.config.apiUrl + '/customer-bonuses/get-balance-card').then(({data}) => {
        if (data.status == 'success') {
            this.balanceLoading = false;
            this.bonusBalance = data.balance;
        }
        }).catch(error => console.log(error));

        //Получаем депозит клиента
        this.depositLoading = true;
        this.$http.post(window.config.apiUrl + '/customers/get-customer-balance-by-id').then(({data}) => {
            if(data.status == 'success') {
                this.depositLoading = false;
                this.deposit = data.deposit.balance/100;
            }   
        }).catch(error => console.log(error));  
    },
}
</script>
<style scoped>
    .header-container > *  {
        flex-grow: 1;
        height: auto;
    }
    .header-container {
        gap: 20px;
    }
    .body-items-container {
        box-shadow: 0px 0px 14px 4px rgb(0 0 0 / 8%) !important;
        overflow:hidden; 
    }
</style>
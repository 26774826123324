<template>
    <v-card class="card-box-container" link :to="url" :disabled="isDisabled" flat>
        <div class="d-flex flex-row">
            <v-card-text class="black--text pt-3 pl-3 pb-0" >
                <template v-if="loading">
                    <v-skeleton-loader class="loader-balance" style="max-width:50px;" type="text"></v-skeleton-loader>
                </template>
                <template v-else>
                    <span style="font-size:18px" class="font-weight-medium">{{title}}<span class="ml-0" style="font-size:90%">₽</span> </span>
                </template>
            </v-card-text >
            <div class="header-item-icon-container" >
                <v-avatar class="header-item-icon-container" :color="STATIC.appThemeColor + '11'" > 
                    <v-icon class="header-item-icon"  size="20" color="primary">
                        {{icon}}
                    </v-icon>
                </v-avatar>
            </div>
        </div>
        <v-card-title class="pa-3 pt-0" >
            <div class="grey--text text-caption" >
                {{caption}}
            </div>
        </v-card-title>
    </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'STATIC'
        ]),
    },
    data() {
        return {
        }
    },
    props: {
        icon: {
            type: String,
            default: 'fas fa-cog'
        },
        title: {
            type: Number,
            default: null
        },
        loading: {
            type: Boolean,
            default: true,
        },
        caption: {
            type: String,
            default: 'Подпись'
        },
        url: {
            type: String,
            default: '/'
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
}
</script>
<style scoped>
    .card-box-container {
        box-shadow: 0px 0px 14px 4px rgb(0 0 0 / 8%) !important;
        width: calc(50% - 10px); 
        overflow:hidden; 
        border-radius: 15px !important; 
        transition: transform 0.2s, box-shadow 0.2s;
        
    }

    .card-box-container:hover {
        text-decoration: none !important;
        box-shadow: rgba(0, 0, 0, 0.05) 0 2px 2px 0 !important;
        transform: translate(0, -2px);
    }

    .header-item-icon-container {
        position:absolute; 
        right: -3px; 
        top: -4px;
    }
    .loader-balance:deep(.v-skeleton-loader__text.v-skeleton-loader__bone) {
        height: 24px;
        margin-bottom: 0;
    }
</style>
<template>
  <div>
    <div class="header-mobile">
      <v-container>
        <div class="top-toolbar">
          <div class="navigate-btn">
            <v-btn icon @click="toBack">
              <v-icon>fas fa-angle-left</v-icon>
            </v-btn>
          </div>
          <div class="page-title">
            Ваши заказы
          </div>
          <div class="second-btn"></div>
        </div>
      </v-container>
    </div>
    <div>
      <v-container>
        <div v-if="!this.$store.getters.STATIC.shopActive">
          <v-alert
              border="left"
              type="grey lighten-1"
          >
            Мы приносим свои извинения, магазин временно недоступен
          </v-alert>
        </div>
        <div v-else>
          <div v-if="loading">
            <v-card
                outlined
            >
              <div v-for="index in 5" :key="index">
                <v-skeleton-loader

                    type="table-heading, list-item-three-line"
                ></v-skeleton-loader>
                <div v-if="index != 5" class="line-dot d-flex pr-3 pl-3"/>
              </div>
            </v-card>
          </div>
          <div v-else-if="orders.length">
            <v-card
                outlined
            >
              <div :key="order.id" v-for="(order, index) in orders">

                <OrderLine :order="order" @delete="deleteOrder"/>
                <div v-if="index != (orders.length-1)" class="line-dot d-flex pr-3 pl-3"/>
              </div>
            </v-card>
          </div>
          <div v-else>
            <v-alert
                border="left"
                type="info"
            >
              У Вас ещё нет заказов
            </v-alert>
            <div class="text-center">
              <v-btn
                  color="success"
                  class="text-decoration-none ma-2 white--text"
                  :href="this.$store.getters.STATIC.shopUrl"
              >
                Перейти в магазин
                <v-icon
                    right
                    dark
                    small
                >
                  fas fa-store-alt
                </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import OrderLine from '@/components/orders/OrderLine.vue'

import {orders} from '@/services/api'

export default {
  data: function () {
    return {
      orders: [],
      loading: true
    }
  },
  components: {
    OrderLine
  },
  methods: {
    async getOrders() {
      try {
        this.orders = await orders.get()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    // Вернутся назад
    toBack: function () {
      this.$router.push('/');
    },
    deleteOrder(number) {
      this.orders = this.orders.filter(item => {
        return item.number != number
      })
    }
  },
  mounted: function () {
    // Получить список услуг чата
    this.getOrders();
  }
}
</script>

<style scoped>
.line-dot:after {
  content: "";
  flex: 1;
  border: 1px dashed #efefef;
}

</style>
<template>
	<div>
		<div class="header-mobile">
			<v-container>
				<div class="top-toolbar">
					<div class="navigate-btn" >
						<v-btn icon @click="toBack">
							<v-icon>fas fa-angle-left</v-icon>
						</v-btn>
					</div>
					<div class="page-title">
						{{title}}
					</div>
					<div class="second-btn">
						<v-btn icon  v-if="false" @click="toTest">
							<v-icon>far fa-question-circle</v-icon>
						</v-btn>
					</div>
				</div>
			</v-container>
		</div>
		<div>
			<v-container>
				<v-tabs v-model="tab" grow show-arrows>
					<v-tab>Пользователи</v-tab>
          <v-tab v-if="STATIC.bonusRoublesEnable || STATIC.inviteFriend">Бонусы</v-tab>
					<v-tab>Безопасность</v-tab>
				</v-tabs>
				<v-sheet>
				<v-tabs-items
          v-model="tab"
          fixed-tabs
				>
					<!-- Пользователи -->
					<v-tab-item>
						
						<v-card flat>
							<v-row>
								<v-col cols="12" class="pb-0">
									<v-alert
										prominent
										text
										border="left"
										color="primary"
										class="px-5"
									>
										<v-row align="start">
											<v-col class="grow">
												<v-chip small color="primary"  class="mb-2">Представитель</v-chip>
												<h6 class="text-h6">{{getUser.surname}} {{getUser.name}} {{getUser.middleName}}</h6>
												<div v-if="getUser.birthday">{{birthdatFormatDate(getUser.birthday)}}</div>
												<div v-if="getUser.groups && getUser.groups.length" class="pt-2">
													<div class="d-flex flex-wrap" style="gap: 0.25rem">
														<template v-for="group in getUser.groups">
															<v-chip small :key="group.name" :color="group.color">
                                <v-icon
                                  v-if="group.icon"
                                  color="currentColor"
                                  left
                                  x-small
                                >
                                  {{ group.icon }}
                                </v-icon>
																{{group.name}}
															</v-chip>
														</template>
													</div>
												</div>
											</v-col>
<!--											<v-col class="shrink" >
												<div>
													<v-btn color="primary" text :disabled="activeUser == getUser.id" @click="selectUser(getUser.id)">Выбрать</v-btn>
												</div>
											</v-col>-->
										</v-row>
									</v-alert>
								
								</v-col>
							</v-row>
						</v-card>
						<v-row>
							<v-col cols="12" xl="4" :key="representative.id" v-for="representative in getRepresentatives">
								<v-card
									class="fill-height d-flex flex-column justify-content-between"
									outlined
								>
									<v-card-text>
										<div class="text-h6 text--primary pb-1">{{representative.surname}} {{representative.name}} {{representative.middleName}}</div>
										<div>{{birthdatFormatDate(representative.birthday)}}</div>
										<div v-if="representative.groups && representative.groups.length" class="pt-1">
											<div class="d-flex flex-wrap" style="gap: 0.25rem">
												<template v-for="group in representative.groups">
													<v-chip small :key="group.name" :color="group.color">
                            <v-icon
                              v-if="group.icon"
                              color="currentColor"
                              left
                              x-small
                            >
                              {{ group.icon }}
                            </v-icon>
														{{group.name}}
													</v-chip>
												</template>
											</div>
										</div>
									</v-card-text>
<!--									<v-card-actions>
										<v-btn text color="primary" @click="selectUser(representative.id)" :disabled="activeUser == representative.id">
											Выбрать
										</v-btn>
									</v-card-actions>-->
								</v-card>
							</v-col>
							<v-col cols="12" xl="4" style="flex-grow: 1" v-if="getRepresentatives && getRepresentatives.length < 8">
								<v-btn class="text-center primary--text p-3 fill-height" @click="toggleCustomerCreateDialog()" outlined style="height: 100%; width: 100%">
									<div class="add-representative">
										<v-avatar color="primary"><v-icon color="white">fa-plus</v-icon></v-avatar>
										<div class="primary--text pt-2">Создать нового пациента</div>
									</div>
								</v-btn>
							</v-col>
						</v-row>
						
					</v-tab-item>
          
          <!-- Бонусы-->
          <v-tab-item v-if="STATIC.bonusRoublesEnable || STATIC.inviteFriend">
            <Cashback />
          </v-tab-item>
          
          <!-- Безопасность -->
					<v-tab-item>
						<SessionsList />
					</v-tab-item>
					
				</v-tabs-items>
				</v-sheet>
				
			</v-container>
			
		</div>
		<CreateRepresentativeDialog v-model="showCreateClientDialog" />
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import SessionsList from "@/views/Information/SessionsList";
import CreateRepresentativeDialog from "@/components/CreateRepresentativeDialog";
import Cashback from '@/views/Information/Cashback';

export default {
	name: 'Information',
 
	data: function () {
		return {
			showCreateClientDialog: false,
			birthdayMenu: false,
			newCustomer: {
				birthday: null,
				surname: null,
				name: null,
				middleName: null,
			},
			createDialogLoading: false,
			representatives: [],
			title: 'Просмотр профиля',
			showConfirmationCreateCustomer: false,
			datePickerBirthday: null,
			tab: null
		}
	},
 
	watch: {
		datePickerBirthday(newVal) {
			if (!newVal) {
				return this.newCustomer.birthday = newVal;
			}
			let time = this.$moment(newVal).format('DD.MM.YYYY');
			if (time != 'Invalid date') {
				return this.newCustomer.birthday = time;
			} else {
				return this.newCustomer.birthday = newVal;
			}
		}
	},
 
	methods: {
		...mapActions({
			setRepresentatives: 'setRepresentativesAction',
			setCurrentUser: 'selectUser'
		}),

		selectUser(idUser) {
			this.setCurrentUser(idUser);
		},

		toBack() {
			this.$router.push('/')
		},

		toTest() {
			this.$router.push('/peer')
		},

		// Открывает диалог
		confirmSaveNewCustomer() {
			// Валидация формы
			if (!this.$refs.newCustomerForm.validate()) {
				return;
			}
			this.showConfirmationCreateCustomer = true;
		},

		editNewCustomer() {
			this.showCreateClientDialog = true;
			this.showConfirmationCreateCustomer = false;
		},

		// Вернуть отформатированную дату записи
		birthdatFormatDate(dateFrom){
				return this.$moment(dateFrom).format("DD.MM.YYYY");
		},

		// Открыть или закрыть модалку создание клиента
		toggleCustomerCreateDialog() {
			this.showCreateClientDialog = !this.showCreateClientDialog;
		},

		closeNewCustomerDialogs() {
			this.showCreateClientDialog = false;
			this.showConfirmationCreateCustomer = false;
		},

		// Сохраняет нового клиента
		saveNewCustomer() {			
			this.createDialogLoading = true;
			let customer = {
				...this.newCustomer
			}
			customer.birthday = this.$moment(customer.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD');
			this.$http.post(window.config.apiUrl + '/customers/representative-create-customer', {
				customer: customer
			}).then( ({data}) => {
				if (data.status == 'success') {
					return this.setRepresentatives([...this.getRepresentatives, {...customer, id: data.id}]);
				}
			}).then(_ => {
				this.showCreateClientDialog = false;
				this.showConfirmationCreateCustomer = false;
				this.newCustomer = {
					birthday: null,
					surname: null,
					name: null,
					middleName: null
				};
				this.$refs.newCustomerForm.resetValidation()
			})
			.catch( error => console.log(error) )
			.finally( _ => this.createDialogLoading = false );
		}
	},
 
	async mounted() {
    const url = new URL(window.location.href);
    if (url.searchParams.get('tab') === 'bonuses') {
      this.tab = 1; // Перекидываем на вкладку с бонусами
    }
    url.searchParams.delete('tab');
    window.history.replaceState({}, document.title, url);
	},
  
	computed: {
		...mapGetters([
			'getRepresentatives',
			'getUser',
			'activeUser',
			'getUser',
      'STATIC'
		]),
	},
	
	components: {
		SessionsList,
		CreateRepresentativeDialog,
    Cashback
	}
}
</script>

<style scoped>

@media screen and  (max-width: 1200px){

}

.add-representative {
	display: flex;
	flex-flow: column;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}

</style>
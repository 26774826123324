<template>
  <v-app class="v-app-section">
    <router-view />
    <NetworkErrorSnackbar />
    <Advertising v-if="GET_ADVERTISING" :advertising="GET_ADVERTISING"></Advertising>
  </v-app> 
</template>

<script>

import router from '@/router'
import eventBus from './eventBus';
import socket from '@/services/socket.js'
import store from '@/store'
import NetworkErrorSnackbar from './components/NetworkErrorSnackbar.vue'
import {mapActions, mapGetters} from "vuex";
import Advertising from '@/components/advertising/Advertising';

export default {
  name: 'app',
  components: {
    NetworkErrorSnackbar,
    Advertising
  },
  router,
  created: function () {
    this.$store.dispatch('loadStatic');

  // Прокрутить чат
  eventBus.$on('scrollChat', () => {
    Math.easeInOutQuad = function (t, b, c, d) {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
      };

      let chatDiv = document.querySelector('.chat-messages');
      if (chatDiv){
        let duration = 600;
        var start = chatDiv.scrollTop,
        change = chatDiv.scrollHeight - start,
        currentTime = 0,
        increment = 20;

        var animateScroll = function(){        
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            chatDiv.scrollTop = val;
            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();

      }
  });

  // Прочитать свои сообщения
  eventBus.$on('readMessages', () => {
    if (store.getters.currentCouponService){
      store.getters.couponServices.forEach(function(couponService){
        // Если есть непрочитаные сообщения
        if (couponService.unreadCount && couponService.id == store.getters.currentCouponService){
          // Отправляем запрос на прочтение
          let request = {
            event: 'readMessage', 
            couponService: store.getters.currentCouponService,
            token: store.getters.token,
            type: 'customer',
            id: store.getters.activeUser
          };
          socket.socketSend(JSON.stringify(request));
          couponService.unreadCount = 0;
          couponService.messages.forEach(function(message){
            if (!message.fromUser && !message.read){
              message.read = true;
            }
          })
        }
      });
    }
  });

  // Начать звонок врачу
  eventBus.$on('startVideoCall', (idCouponService) => {
    socket.startVideoCall(idCouponService);
  });

  // Открытия чата по услуге
  eventBus.$on('openCouponService', (id) => {
      this.$store.dispatch('setCurrentCouponService', id);
      if (store.getters.noRulesAudio){
          let audio = new Audio(require('@/assets/empty.mp3')); 
          audio.play();
      }
  });

  // Завершить вызов
  eventBus.$on('rejectCall', (idCouponService) => {
    this.$store.dispatch('setCallStatus', 'wait');
    socket.customerRejectCall(idCouponService);
  });
  
  // К списку диалогов в мобильной версии
  eventBus.$on('goToBack', () => {
    if (!this.currentCouponService){
      this.$router.push('/')
    }
    this.$store.dispatch('setCurrentCouponService', null);
  });
  },



  mounted() {
    try {
      const timestamp = localStorage.getItem('dateTimesAdvertising') || 0;
      if((new Date()).getTime() - timestamp > 60*60*1000){
        this.getAdvertising().then((r) => {
          if(r.data.advertising) {
            localStorage.setItem('dateTimesAdvertising', (new Date()).getTime());
          }
        });
        // localStorage.setItem('dateAdvertising', (new Date()).getTime());
      }
    }catch (err){
      throw err;
    }
    // if(this.$vuetify.breakpoint.mdAndDown) {

    // }
  },

  computed: {
    ...mapGetters([
      'GET_ADVERTISING'
    ]),
  },
  methods:{
    ...mapActions([
      'getAdvertising'
    ]),
  }
}
</script>

<style>
:root{
  --primary-color: #82C831;
}
html,
body {
  font-family: 'Roboto', sans-serif !important;
  height: 100%;
}

.v-app-section {
  opacity: 0;
  pointer-events: none;
  transition: opacity .15s ease-in-out;
}

.loaded{
  opacity: 1;
  pointer-events: all;
}

</style>

<template>
<!--  <div>{{advertising}}</div>-->
  <div ref="container">
    <swipe-modal
        v-if="$vuetify.breakpoint.mdAndDown"
        class="swipe-modal"
        v-model="modal"
        :contents-height="modalHeight+'px'">
      <div ref="modalWrap" class="swipe-modal__wrapper">
        <v-card>

          <v-card-title>
            {{ advertising.title }}
          </v-card-title>

          <v-card-text>
            {{ advertising.text }}
          </v-card-text>

          <v-carousel height="230px" :hide-delimiters="getEntitiesItem" :show-arrows="false">
            <v-carousel-item v-for="(item, index) in getImgEntitiesItems" :key="index" cover>
              <img style="object-position: center center;object-fit:cover; height: 230px; width: 100vw" :src="item.entity.srcUrl" :href="item.entity.clickUrl" @click="sendButtonEvent(item.entity.clickUrl)">
            </v-carousel-item>
          </v-carousel>

          <div class="modal-button" v-for="(item, index) in getBtnEntitiesItems" :key="index" >
            <div class="btn-wrapper">
              <v-btn color="primary" block elevation="0" @click="sendButtonEvent(item.entity.clickUrl)" >
                {{item.entity.buttonName}}
              </v-btn>
            </div>
          </div>
        </v-card>
      </div>
    </swipe-modal>

    <v-dialog
        v-if="!$vuetify.breakpoint.mdAndDown"
        v-model="modal"
        width="700px"
        absolute="true"
        close-on-back="1000"
    >
      <v-card>

        <div style="display: flex; justify-content: space-between;">
          <v-card-title class="d-flex p-2 justify-space-between">
            {{ advertising.title }}
          </v-card-title>
          <v-btn icon @click="modal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-card-text class="p-2">
          {{ advertising.text }}
        </v-card-text>
        <v-carousel class="px-2" height="400px" :hide-delimiters="getEntitiesItem" :show-arrows="false">
          <v-carousel-item style=" " v-for="(item, index) in getImgEntitiesItems" :key="index" cover :src="item.entity.srcUrl" @click="sendButtonEvent(item.entity.clickUrl)">
          </v-carousel-item>
        </v-carousel>
        <v-card-actions >
          <div style="display: flex; flex-direction: column; width: 100%;" >
            <v-btn v-for="(item, index) in getBtnEntitiesItems" :key="index" elevation="0" class="mb-1" color="primary" block @click="sendButtonEvent(item.entity.clickUrl)" >
              {{item.entity.buttonName}}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { swipeModal } from '@takuma-ru/vue-swipe-modal';
import {defineComponent, ref, storeToRefs, onMounted, nextTick, onUpdated, watch, reactive} from '@vue/composition-api';
import store from '@/store';
import axios from "axios";

export default defineComponent({
    setup () {
        const modal = ref(false);
        const container = ref();
        const modalHeight = ref(0);
        const modalWrap = ref(null);
        const modalDialog = ref(false)
      // alert(modalDialog.value);

        watch(() => reactive({showModalReactiveProp: store.state.showModal}), (currentValue) => {
          if(currentValue) {
            const data = {
              idCustomer: store.state.user.id,
              idAdvertising: store.state.advertising.id,
              eventType: 'view'
            }
            // alert(this.props)
            modal.value = currentValue.showModalReactiveProp;
            axios.post(`${window.config.apiUrl}/advertising-conversions`, data)
          }
        })

        onUpdated(()=>{
          nextTick(() => {
            if(modalWrap.value){
              modalHeight.value = Math.min(modalWrap.value.clientHeight, window.innerHeight);
            }
          })
        })

        return {
          modal,
          container,
          modalWrap,
          modalHeight
        }

    },

    props: {
      advertising: {
        type: Object,
        required: true
      }
    },

    computed: {
      getImgEntitiesItems(){
        if(this.advertising.entities){
          return this.advertising.entities.filter(item =>{
            return item.type === "media";
          })
        }
      },

      getEntitiesItem(){
        let count;
        if(this.advertising.entities) {
          this.advertising.entities.forEach(item =>{
            if (item.type === "media") { count ++; }
          })
        }
        return !(count>1);
      },

      getBtnEntitiesItems(){
        if(this.advertising.entities){
          return this.advertising.entities.filter(item =>{
            return item.type === "button";
          })
        }
      },
    },

    methods: {

      sendButtonEvent(src) {
        const data = {
          idCustomer: this.$store.getters.getUser.id,
          idAdvertising: this.advertising.id,
          eventType: 'click'
        }
        axios.post(`${window.config.apiUrl}/advertising-conversions`, data)
            .then(r => console.log(r))
            .catch((e) => {
              throw e;
            })
        window.open(src)
        }
      },

    components: {
        swipeModal
    }
})
</script>

  <style scoped>
  .v-card__title {
      font-size: 1.20em !important;
      line-height: 1.25em !important;
      word-break: break-word;
      padding: 5px 10px 8px 10px;
  }
  .theme--light.v-card > .v-card__text {
    color: rgba(0, 0, 0, 1);
    font-size: 1em !important;
    padding: 0px 10px 8px 10px;
  }

  .modal-button {
      padding: 0;

  }
  .swipe-modal__wrapper{
    max-height: 80vh;
    overflow: auto;
  }

  @media screen and (max-width:350px){
      .swipe-modal-carousel {
          height: 35vh !important;
      }
  }
  .v-image__image--cover {
      background-size: contain !important;
  }
  .btn-wrapper{
    padding: 4px 10px 3px 10px;

  }

</style>
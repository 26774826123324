<template>
    <v-container class="mb-2 pa-0">
        <v-card class="body-item" link :to="url" flat tile>
            <v-list class="pa-0" >
                <v-list-item >
                  <v-list-item-icon class="mr-5 mt-2 mb-2" >
                    <v-avatar class="body-item-icon-container" :color="STATIC.appThemeColor + '11'" tile>
                            <v-icon class="body-item-icon" color="primary" size="20" v-text="icon"></v-icon>
                    </v-avatar>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        text: { 
            type: String,
            default: 'Заголовок'
        },
        icon: {
            type: String,
            default: 'fas fa-wallet'
        },
        subtitle : {
            type: String,
            default: 'Подзаголовок'
        },
        url: {
            type: String,
            default: '#'
        }
    },

    computed: {
        ...mapGetters([
            'STATIC'
        ])
    }
}
</script>

<style scoped>
    .body-item:hover {
        text-decoration: none !important;
        box-shadow: rgba(0, 0, 0, 0.05) 0 2px 2px 0 !important;
        transform: translate(0, -2px);
    }
    .body-item {
        box-shadow: 0px 0px 14px 4px rgb(0 0 0 / 8%) !important;
        border-radius: 15px !important;

    }
    .body-item-icon-container {
        border-radius: 10px !important;
    }
</style>
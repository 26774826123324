<template>
    <div class="text-center">
      <v-dialog
        @click:outside="noAuthShop()"
        v-model="dialogAuth"
        width="500"
      >        
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
           Авторизация
          </v-card-title>

          <v-card-text class="text-center p-3 pb-0">
            <h6>Здравствуйте, {{getUser.surname}} {{getUser.name}} {{getUser.middleName}}</h6>
            Вы хотите потвердить авторизацию в онлайн магазине?
            <v-icon
              large
              color="blue-grey darken-2"
            >
              mdi-shopping-outline
            </v-icon>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              text
              @click="authShop()"
            >
              Да
            </v-btn>
            <v-btn
              text
              @click="noAuthShop()"
            >
              Нет
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>


<script>
import {shop} from '@/services/api'
import store from '@/store'
import router from '@/router'
import { mapGetters} from 'vuex';
export default {
    name: 'ShopAuth',
    data() {
        return {
            dialogAuth: true
        }
    },    
    mounted() {
      if (!localStorage.getItem('authService')){
        window.top.location.replace(this.$store.getters.STATIC.shopUrl+localStorage.getItem('authServiceUrl'))
      }
    },
    methods: {
      noAuthShop() {
        store.dispatch('logout')
        router.push('/')
      },
      async authShop() {
        // тут функция присвоение персональному токену его кастомера\
        let token = ''
        try {
          let ax = await shop.getPersonalTokenShop()
        
          if (ax.data == "" || ax.data === null) {
            alert("Ошибка авторизации!!!")
          } 
          else
          {          
            token = ax.data
          }
        } catch (e) {
          alert("Ошибка авторизации!!!")
        }

        localStorage.removeItem('authService')
        window.top.location.replace(this.$store.getters.STATIC.shopUrl + localStorage.getItem('authServiceUrl')+( token ? '?setRefreshToken='+token : ''))
      }      
    },
    computed: {
		...mapGetters([
			'getRepresentatives',
			'getUser',
			'activeUser',
			'getUser',
      'STATIC'
		]),
	},
}
</script>

<style>
.v-overlay__scrim {
    background-color: white !important;
}
</style>
<template>
  <div>
    <div class="header-mobile">
      <v-container>
        <div class="top-toolbar">
          <div class="navigate-btn"></div>
          <div class="page-title">
            {{title}}
          </div>
          <div class="second-btn">
            <v-btn icon color="white">
              <v-icon>far fa-question-circle</v-icon>
            </v-btn>
          </div>
        </div>
      </v-container>
    </div>
    <v-container >
      <div v-if="!loading">
        <div>
          <v-alert
            :icon="orderStatus == 2 ? 'mdi-check' : 'mdi-alert-circle'"
            prominent
            text
            color="grey darken-1"
            class="px-5"
          >
            <v-row align="center">
              <v-col class="grow">
                <h5 v-if="orderStatus == 2">Ваш заказ {{this.orderNumber ? '№ ' + this.orderNumber : ''}} успешно оплачен</h5>
                <h5 v-if="orderStatus != 2">Не удалось оплатить заказ {{this.orderNumber ? '№ ' + this.orderNumber : ''}}</h5>
                <h6 v-if="orderStatus != 2">{{this.errorMessage}}</h6>
                <p v-if="orderStatus == 2">
                  <span v-if="isShop">
                    Ваш платёж будет обработан в течении 15 минут
                  </span>
                  <span v-else>
                    Денежные средства поступят на счет в течении 15 минут
                  </span>
                </p>
              </v-col>
            </v-row>
          </v-alert>
          <div class="text-center w-100">
            <v-btn v-if="isShop" rounded color="primary" dark @click="toOrders">Перейти к моим заказам</v-btn>
            <v-btn v-else rounded color="primary" dark @click="toAppointments">Перейти к моим записям</v-btn>
          </div>
        </div>
      </div>
      <div v-if="loading">
        <v-skeleton-loader class="mx-0 p-0" type="list-item-avatar-three-line"></v-skeleton-loader>
      </div>
    </v-container>
  </div>
</template>

<script>

import socket from '@/services/socket.js'
    export default {
        name: 'SuccessPayment',
        data() {
            return {
                loading: true,
                orderNumber: '',
                orderStatus: null,
                errorMessage: false,
                isShop: false,
                title: 'Оплата заказа'
            }
        },
        // mounted (){
        //     let orderId = this.$route.query.orderId;
        //     let isShop = this.$route.query.isShop;
        //     this.$http.get(window.config.apiUrl + '/payments/get-order-status?orderId=' + orderId+'&isShop='+isShop)
        //         .then((response) => {
        //             this.orderStatus = response.data.orderStatus;
        //             this.orderNumber = isShop ? response.data.orderNumber.split(' ')[0] : response.data.orderNumber;
        //             this.isShop = isShop ;
        //             this.errorMessage = response.data.actionText ? response.data.actionText : response.data.errorMessage;
        //             console.log(response);
        //             this.loading = false;
        //             // Если заказ оплачен
        //             if (response.data.orderStatus == 2){
        //               // Отправляем события новой услуги чата
        //               let request = {
        //                   event: 'customerPayment', 
        //                   orderId:  response.data.orderId,
        //                   token: this.$store.getters.token,
        //                   type: 'customer',
        //                   id: this.$store.getters.getUser.id
        //               };
        //               socket.socketSend(JSON.stringify(request));
        //             }
        //         })
        //         .catch(function (error) {
        //           this.loading = false;
        //           console.log(error);
        //         });
        // },
        methods: {
            toAppointments() {
                this.$router.push('/appointments')
            },
            toOrders() {
                this.$router.push('/orders')
            }
        }
    }

</script>

<style scoped>


</style>
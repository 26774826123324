// Хранилище статики

export default {
  static: {
    appLoginTitle: '',
    appLogoUrl: '',
    appFaviconUrl: '',
    appFooterContent: '',
    appHeaderContent: '',
    appMapIframe: '',
    appClinicUrl: '',
    appPriceUrl: '',
    appContacts: '',
    appAgreementUrl: '',
    appPrivacyUrl: '',
    appUseTelemedicine: '',
    appThemeColor: '',
    useAppAcquiring: '',
    jsonSiteServiceButton: '',
    pages: {
      contacts: true,
      map: true,
      paymentProcess: true
    },
    recoveryChannels: []
  }
}
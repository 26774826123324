<template>
  <div>
    <div class="header-mobile">
      <v-container>
        <div class="top-toolbar">
          <div class="navigate-btn" >
            <v-btn icon  @click="toBack" >
              <v-icon>fas fa-angle-left</v-icon>
            </v-btn>
          </div>
          <div class="page-title">
            {{title}}
          </div>
          <div class="second-btn">
            <v-btn icon color="white">
              <v-icon>far fa-question-circle</v-icon>
            </v-btn>
          </div>
        </div>
      </v-container>
    </div>
    <v-container>
      <div v-if="!loading">
        <v-alert
          icon="mdi-alert-circle"
          prominent
          text
          color="grey darken-1"
          class="px-5"
        >
          <v-row align="center">
            <v-col class="grow">
              <h5>Не удалось оплатить заказ № {{this.orderNumber}}</h5>
              <h6>{{this.errorMessage}}</h6>
            </v-col>
          </v-row>
        </v-alert>
         <div class="text-center w-100">
            <v-btn v-if="isShop" rounded color="primary" dark @click="toOrders">Перейти к моим заказам</v-btn>
            <v-btn v-else rounded color="primary" dark @click="toAppointments">Перейти к моим записям</v-btn>
          </div>
      </div>
      <div v-if="loading">
        <v-skeleton-loader class="mx-0 p-0" type="list-item-avatar-three-line"></v-skeleton-loader>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'SuccessPayment',
  data() {
    return {
      loading: true,
      orderNumber: '',
      orderStatus: null,
      errorMessage: false,
      isShop: false,
      title: 'Ошибка оплаты',
    }
  },
  methods: {
    toBack() {
      this.$router.push('/')
    },
    toAppointments() {
      this.$router.push('/appointments')
    },
    toOrders() {
        this.$router.push('/orders')
    }
  },
  mounted (){
    let orderId = this.$route.query.orderId;
    let isShop = this.$route.query.isShop;
    // Проверяем заказ
    this.$http.get(window.config.apiUrl + '/payments/get-order-status?orderId=' + orderId)
      .then((response) => {
        // Если нет ошибок
        this.errorMessage = response.data.actionText;
        this.orderNumber = response.data?.orderNumber ? (isShop ? response.data.orderNumber.split(' ')[0] : response.data.orderNumber) : orderId;
        this.isShop = isShop ;
        console.log(111,response);
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.log(error);
      }); 
  }
}

</script>

<style scoped>


</style>